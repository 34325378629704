import debounce from 'lodash-es/debounce'
import omitBy from 'lodash-es/omitBy'
import { computed, reactive, ref } from 'vue'
import { AlgoliaWorker, getDefaultIndex } from '/~/core/algolia'
import cdn from '/~/utils/cdn'
import { useCms } from '/~/composables/cms/use-cms'
import { useExtensions } from '/~/composables/extensions'
import { useSearch } from '/~/composables/search'
import { useUser } from '/~/composables/user'

const { pages } = useCms()
const { registerAlgoliaSearch } = useSearch()

const GC_CINEMA_MODULE_NAME = 'gift-cards-cinema'

const gcCinemaAlgoliaWorker = reactive(new AlgoliaWorker())
const gcCinemaCategories = ref(null)
const gcCinemaSearchQuery = ref('')
const gcCinemaSelectedCardType = ref(null)
const gcCinemaSelectedSorting = ref(null)
const gcCinemaSelectedCategory = ref(null)
const gcCinemaSelectedGroup = ref(null)
const gcCinemaCmsPageName = ref(null)

const { getManifestByName, getConfigByName, getMetaByName } = useExtensions()

const gcCinemaModule = computed(() => getManifestByName(GC_CINEMA_MODULE_NAME))
const gcCinemaConfig = computed(
  () => getConfigByName(GC_CINEMA_MODULE_NAME) || {}
)
const gcCinemaMeta = computed(() => getMetaByName(GC_CINEMA_MODULE_NAME))

const gcCinemaLabel = computed(
  () => gcCinemaModule.value?.label ?? 'Gift Cards'
)
const gcCinemaCms = computed(() =>
  gcCinemaCmsPageName.value ? pages.value[gcCinemaCmsPageName.value] : {}
)
const gcCinemaGroupBy = computed(() => gcCinemaConfig.value?.groupBy)

const gcCinemaIsEmpty = computed(() => gcCinemaAlgoliaWorker.length === 0)
const gcCinemaAlgoliaIndexes = computed(
  () => gcCinemaConfig.value?.indexes ?? []
)
const gcCinemaSearchConfig = computed(() => gcCinemaConfig.value?.search ?? {})
const gcCinemaSearchGroup = computed(
  () => gcCinemaSearchConfig.value?.group ?? gcCinemaLabel.value
)

const gcCinemaDefaultIndex = computed(() =>
  getDefaultIndex(gcCinemaConfig.value)
)
const isGcCinemaEnabledForPhysical = computed(() => false)

const gcCinemaCardTypes = computed(() => {
  const types = [{ value: 'digital', label: 'Digital' }]

  if (isGcCinemaEnabledForPhysical.value) {
    types.push({ value: 'physical', label: 'Physical' })
  }

  return types
})
const gcCinemaSortValues = computed(() => {
  return gcCinemaAlgoliaIndexes.value.map((index) => {
    return {
      text: index.label,
      value: index.name,
      default: index.default,
    }
  })
})
const gcCinemaDefaultSortValues = computed(
  () =>
    gcCinemaSortValues.value.find((v) => v.default) ||
    gcCinemaSortValues.value[0]
)
const gcCinemaRouteState = computed(() => {
  const routeState = {
    params: {
      type: gcCinemaSelectedCardType.value,
      category: gcCinemaSelectedCategory.value,
    },
    query: {
      search: gcCinemaSearchQuery.value,
      sort:
        gcCinemaSelectedSorting.value !== gcCinemaDefaultIndex.value
          ? gcCinemaSelectedSorting.value
          : '',
    },
  }

  routeState.query = omitBy(routeState.query, (v) => !v)

  return routeState
})

const gcCinemaFilters = computed(() => {
  const { user } = useUser()

  const filters: string[] = [
    `memberships:${user.value.membershipId}`,
    gcCinemaConfig.value?.filters || gcCinemaConfig.value?.filter,
  ]

  if (!isGcCinemaEnabledForPhysical.value) {
    filters.push('physical:false')
  }

  if (
    gcCinemaSelectedGroup.value &&
    gcCinemaGroupBy.value?.values?.includes(gcCinemaSelectedGroup.value)
  ) {
    const groupValues = (gcCinemaSelectedGroup.value as string).split(',')
    const groupFilters = groupValues.map(
      (value) => `${gcCinemaGroupBy.value?.key}:${value}`
    )

    filters.push(`(${groupFilters.join(' OR ')})`)
  }

  return filters.filter(Boolean).join(' AND ')
})

const gcCinemaQueryFilters = computed(() => {
  const filters = [gcCinemaFilters.value]

  if (isGcCinemaEnabledForPhysical.value) {
    filters.push(`physical:${gcCinemaSelectedCardType.value === 'physical'}`)
  }

  return filters.filter((v) => v).join(' AND ')
})
const gcCinemaQuickBuyEnabled = computed(() => {
  return gcCinemaModule.value?.payment?.quick_buy?.enabled ?? false
})
const isGcCinemaFiltersSelected = computed(() => {
  return (
    gcCinemaSearchQuery.value !== '' ||
    (gcCinemaSelectedSorting.value !== gcCinemaDefaultSortValues.value?.value &&
      gcCinemaSelectedSorting.value !== null) ||
    (gcCinemaSelectedCategory.value !== gcCinemaCategories.value?.[0].id &&
      gcCinemaSelectedCategory.value !== null)
  )
})

function resetGcCinema() {
  gcCinemaAlgoliaWorker.reset()
  gcCinemaCategories.value = null
  gcCinemaSearchQuery.value = ''
  gcCinemaSelectedCardType.value = null
  gcCinemaSelectedSorting.value = gcCinemaSortValues.value?.[0]?.value ?? null
  gcCinemaSelectedCategory.value = null
  gcCinemaSelectedGroup.value = null
}

function resetGcCinemaFilters() {
  gcCinemaSearchQuery.value = ''
  gcCinemaSelectedSorting.value = gcCinemaSortValues.value?.[0]?.value ?? null
  gcCinemaSelectedCategory.value = null
}

async function initGcCinema() {
  gcCinemaAlgoliaWorker.setParams(gcCinemaConfig.value)

  registerAlgoliaSearch({
    group: 'Cinema',
    order: 1,
    config: gcCinemaConfig.value,
    algolia: {
      params: {
        filters: gcCinemaFilters.value,
        attributes: [
          'offer_name',
          'retailer_name',
          'retailer_slug',
          'retailer_alt_logo',
          'retailer_logo',
          'offer_external_id',
          'offer_tags',
        ],
      },
    },
    mapping: (item) => {
      return {
        id: item.objectID,
        image: cdn(item.retailer_logo)
          .preview('300x300')
          .format('auto')
          .quality('smart')
          .url(),
        label: item.retailer_name,
        offer: item.offer_name,
        target: {
          name: 'cinema-location',
          params: {
            slug: item.retailer_slug,
            id: item.offer_external_id,
          },
        },
        meta: {
          physical: isGcCinemaEnabledForPhysical.value,
        },
      }
    },
  })
}

async function syncGcCinemaState({ to }) {
  resetGcCinema()

  gcCinemaCmsPageName.value = to.meta.page
  gcCinemaSelectedCardType.value = to.params.type
    ? to.params.type
    : gcCinemaCardTypes.value?.[0]?.value

  if (to.params.category) {
    gcCinemaSelectedCategory.value = to.params.category
  }

  gcCinemaSelectedSorting.value = to.query.sort
    ? to.query.sort
    : gcCinemaDefaultIndex.value

  if (to.query.search) {
    gcCinemaSearchQuery.value = to.query.search
  }

  if (to.params.groupSlug) {
    gcCinemaSelectedGroup.value = to.params.groupSlug
  }

  getGcCinemaCategories()
  getGcCinemaContent()
}

async function getGcCinemaCategories() {
  gcCinemaCategories.value = await gcCinemaAlgoliaWorker.getCategories({
    index: gcCinemaDefaultIndex.value,
    firstLabel: 'All categories',
    search: {
      filters: gcCinemaQueryFilters.value,
      facets: ['classifications'],
      facetFilters: [['classifications']],
    },
  })
}

const getGcCinemaContent = debounce(() => {
  gcCinemaAlgoliaWorker.reset()

  return gcCinemaAlgoliaWorker.getData({
    index: gcCinemaSelectedSorting.value,
    search: {
      query: (gcCinemaSearchQuery.value || '').trim(),
      attributes: [
        'offer_name',
        'offer_subtitle',
        'offer_logo',
        'retailer_name',
        'retailer_slug',
        'retailer_alt_logo',
        'retailer_logo',
        'offer_tags',
      ],
      filters: gcCinemaQueryFilters.value,
      facets: ['classifications'],
      facetFilters: [
        `classifications:${gcCinemaAlgoliaWorker.decodePath(
          gcCinemaSelectedCategory.value
        )}`,
      ],
    },
  })
}, 100)

export function useGcCinema() {
  return {
    gcCinemaAlgoliaWorker,
    gcCinemaCategories,
    gcCinemaSearchQuery,
    gcCinemaSelectedCardType,
    gcCinemaSelectedSorting,
    gcCinemaSelectedCategory,
    gcCinemaCmsPageName,
    gcCinemaModule,
    gcCinemaConfig,
    gcCinemaMeta,
    gcCinemaSearchGroup,
    gcCinemaLabel,
    gcCinemaCms,
    gcCinemaIsEmpty,
    gcCinemaAlgoliaIndexes,
    gcCinemaDefaultIndex,
    isGcCinemaEnabledForPhysical,
    gcCinemaCardTypes,
    gcCinemaSortValues,
    gcCinemaDefaultSortValues,
    gcCinemaRouteState,
    gcCinemaQueryFilters,
    gcCinemaQuickBuyEnabled,
    isGcCinemaFiltersSelected,
    resetGcCinema,
    resetGcCinemaFilters,
    initGcCinema,
    syncGcCinemaState,
    getGcCinemaCategories,
    getGcCinemaContent,
    gcCinemaGroupBy,
    gcCinemaSelectedGroup,
  }
}
